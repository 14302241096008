.nutritionist-plan-table {
    padding: 20px;

    .form-wrapper {
        .top-section {
            Form {
                display: flex;
                label {
                    font-weight: 600;
                }
                .filter-by-section {
                    margin-left: 30px;

                    &.ant-select-selector {
                        padding: 0px 50px;

                    }
                }

                .ant-select-selector {
                    padding: 0 30px;
                }

                .search {
                    margin-left: 100px;
                    text-align: right;
                    align-items: end;

                    Input {
                        max-width: 100%;
                        width: 200%;
                        padding: 0px 50px;
                    }
                }

                Button {
                    background-color: #FBB900;
                    color: black;
                    font-weight: 700;
                    width: 100%;
                    padding: 0px 50px;
                    margin-right: 50px;

                }

                .btn1 {
                    margin-left: 30px;
                }

                .btn2 {
                    margin-left: 30px;
                }

            }
        }
    }
}