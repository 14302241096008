.login-form {
  width: 100%;
  margin-top: 0px;

  h1 {
    font-size: 3rem;
  }

  h2 {
    margin-top: 10px;
    font-size: 1.8rem;
    font-weight: 400;
  }

  .form-wrapper {
    margin-top: 1.5rem;

    .form-group {
      .login-form-forgot {
        color: #8bc266;
        margin-bottom: 0px;
        text-align: right;
        float: right;
        font-weight: 400;
        padding: 0px;
        text-decoration: underline;
        margin-top: 5px;
      }

      .signup {
        color: #8bc266;
        margin-left: 0.2rem;
      }
    }
  }
}
