.flex {
  display: flex;
  /* height: 100vh; */
}

.leftSide {
  width: 50%;
  background-color: #e68f00;
  display: flex;
  justify-content: center;
  align-items: center;
}

.rightSide {
  padding: 2rem;
  background-color: #f5f3f3;
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.formContainer {
  max-width: 400px;
  width: 100%;
  box-sizing: border-box;
}

.heading {
  text-align: center;
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 3rem;
}

.error {
  color: red;
  margin-bottom: 1rem;
}

.label {
  display: block;
  font-size: 0.875rem;
  margin-bottom: 0.25rem;
}

.input {
  width: 100%;
  border: 2px solid #fff2d6;
  border-radius: 8px;
  margin-bottom: 2rem;
  padding: 10px 15px;
  font-size: 1rem !important;
}

.button {
  width: 105%;
  background-color: #e68f00;
  color: black;
  border: none;
  padding: 15px;
  border-radius: 10px;
  font-weight: 600;
  font-family: "Rubik", sans-serif !important;
  cursor: pointer;
}

.button:hover {
  background-color: #d48300;
}

/* Responsive Styles for Mobile Screens */
@media (max-width: 768px) {
  .flex {
    flex-direction: column;
  }

  .leftSide {
    display: none;
  }

  .rightSide {
    width: auto;
    height: 100vh;
    justify-content: center;
    align-items: center;
  }
  .input {
    margin-left: 10px;
    width: 60%;
  }
  .button {
    margin-left: 10px;
    width: 65%;
  }
  .heading {
    margin-left: 10px;
    font-size: 1rem;
    text-align: left;
  }
}
