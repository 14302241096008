

.ms-auto {
  margin-left: auto!important;
}

.me-auto {
  margin-right: auto!important;
}

.mt-0 {
  margin-top: 0px!important;
}

.mt-1 {
  margin-top: 5px!important;
}

.mt-2 {
  margin-top: 10px!important;
}

.mt-3 {
  margin-top: 15px!important;
}

.mt-4 {
  margin-top: 20px!important;
}

.mt-5 {
  margin-top: 25px!important;
}

.mb-0 {
  margin-bottom: 0px!important;
}

.mb-1 {
  margin-bottom: 5px!important;
}

.mb-2 {
  margin-bottom: 10px!important;
}

.mb-3 {
  margin-bottom: 15px!important;
}

.mb-4 {
  margin-bottom: 20px!important;
}

.mb-5 {
  margin-bottom: 25px!important;
}

.mr-0 {
  margin-right: 0px!important;
}

.mr-1 {
  margin-right: 5px!important;
}

.mr-2 {
  margin-right: 10px!important;
}

.mr-3 {
  margin-right: 15px!important;
}

.mr-4 {
  margin-right: 20px!important;
}

.mr-5 {
  margin-right: 25px!important;
}

.ml-0 {
  margin-left: 0px!important;
}

.ml-1 {
  margin-left: 5px!important;
}

.ml-2 {
  margin-left: 10px!important;
}

.ml-3 {
  margin-left: 15px!important;
}

.ml-4 {
  margin-left: 20px!important;
}

.ml-5 {
  margin-left: 25px!important;
}

.pt-0 {
  padding-top: 0px!important;
}

.pt-1 {
  padding-top: 5px!important;
}

.pt-2 {
  padding-top: 10px!important;
}

.pt-3 {
  padding-top: 15px!important;
}

.pt-4 {
  padding-top: 20px!important;
}

.pt-5 {
  padding-top: 25px!important;
}

.pb-0 {
  padding-bottom: 0px!important;
}

.pb-1 {
  padding-bottom: 5px!important;
}

.pb-2 {
  padding-bottom: 10px!important;
}

.pb-3 {
  padding-bottom: 15px!important;
}

.pb-4 {
  padding-bottom: 20px!important;
}

.pb-5 {
  padding-bottom: 25px!important;
}

.pr-0 {
  padding-right: 0px!important;
}

.pr-1 {
  padding-right: 5px!important;
}

.pr-2 {
  padding-right: 10px!important;
}

.pr-3 {
  padding-right: 15px!important;
}

.pr-4 {
  padding-right: 20px!important;
}

.pr-5 {
  padding-right: 25px!important;
}

.pl-0 {
  padding-left: 0px!important;
}

.pl-1 {
  padding-left: 5px!important;
}

.pl-2 {
  padding-left: 10px!important;
}

.pl-3 {
  padding-left: 15px!important;
}

.pl-4 {
  padding-left: 20px!important;
}

.pl-5 {
  padding-left: 25px!important;
}

.px-0 {
  padding-left: 0px!important;
  padding-right: 0px!important;
}

.px-1 {
  padding-left: 5px!important;
  padding-right: 5px!important;
}

.px-2 {
  padding-left: 10px!important;
  padding-right: 10px!important;
}

.px-3 {
  padding-left: 15px!important;
  padding-right: 15px!important;
}

.px-4 {
  padding-left: 20px!important;
  padding-right: 20px!important;
}

.px-5 {
  padding-left: 25px!important;
  padding-right: 25px!important;
}

.py-0 {
  padding-top: 0px!important;
  padding-bottom: 0px!important;
}

.py-1 {
  padding-top: 5px!important;
  padding-bottom: 5px!important;
}

.py-2 {
  padding-top: 10px!important;
  padding-bottom: 10px!important;
}

.py-3 {
  padding-top: 15px!important;
  padding-bottom: 15px!important;
}

.py-4 {
  padding-top: 20px!important;
  padding-bottom: 20px!important;
}

.py-5 {
  padding-top: 25px!important;
  padding-bottom: 25px!important;
}

.p-0 {
  padding: 0px!important;
}

.p-1 {
  padding: 5px!important;
}

.p-2 {
  padding: 10px!important;
}

.p-3 {
  padding: 15px!important;
}

.p-4 {
  padding: 20px!important;
}

.p-5 {
  padding: 25px!important;
}

.d-flex {
  display: flex!important;
}

.d-inline-flex {
  display: inline-flex!important;
}

.align-items-center {
  align-items: center!important;
}

.justify-content-center {
  justify-content: center!important;
}
.justify-content-around {
  justify-content: space-around!important;
}
.justify-content-between {
  justify-content: space-between!important;
}
.text-start {
  text-align: left!important;
}
.text-end {
  text-align: right!important;
} 

.text-capitalize{
  text-transform: capitalize!important;
}
.w-100 {
  width: 100%;
}