.search-bar {
  display: flex;
  align-items: center;
  // background: #fff;
  padding: 8px 10px;
  border-radius: 5px;
  border: 1px solid var(--brown);
  &:has(input:focus) {
    background: #fff;
  }
  svg {
    margin-right: 10px;
    max-height: 20px;
  }
  input {
    min-width: 220px;
    width: 330px;
    border: 0px;
    outline: none;
    font-size: 1rem;
    background: none;
  }
}
