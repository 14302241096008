.pet-deceased-form {
    .form-section {
        label {
            font-weight: 500;
            font-size: 18px;
            margin-bottom: 10px;
        }
        .radio-button {
           margin-top: 0px;
           label {
            font-weight: 400;
            font-size: 15px;
           }
        }
    }
    .button-section {
        margin-top: 170px;
    }
}