.thank-you-form {
    // padding: 50px 0;
    width: 100%;

    h1 {
        // color: #8B4E17;
        // text-align: left;
        // margin-top: 80px;
        // margin-bottom: 0px;
        // margin-left: 30px;
        font-size: 3.0rem;
        // font-weight: 800;
    }

    h2 {
        // color: #8B4E17;
        // text-align: left;
        margin-top: 0px;
        font-size: 2.5rem;
        // margin-left: 30px;
        font-weight: 600;

    }

    h3 {
        color: #8B4E17;
        text-align: left;
        font-size: 2.5rem;
        margin-bottom: 0px;

    }

    p {
        text-align: left;
        margin-top: 20px;
        font-weight: 700;
        // margin: 0px;

    }

    .form-wrapper {
        margin-top: 1.5rem;

        .form-group {
            font-weight: 700;

            .form-control {
                border-color: #FBB900;
            }

            &.button-section {
                .ant-form-item {
                    margin-bottom: 0px;
                    margin-top: 50px;
                }
            }

            .back-btn {
                background-color: #FBB900;
                color: black;
                font-weight: 700;
                // width: 80%;
                margin-top: 1.0rem;
            }

        }
    }
}