.vet-practice-form {
    .top-section {
        margin-left: 70px;
    }

    h2 {
        line-height: 45.4px;
    }

    h3 {
        color: #8B4E17;
        text-align: left;
        margin-top: 12px;
        font-size: 1.5rem;
        font-weight: 400;
    }

    .form-wrapper {
        margin-top: 10px;

        label {
            font-weight: 700 !important;
            font-size: 20px;
            color: #2D2D2D;
        }

        .ant-col {
            margin-top: 25px;
        }

        .volume-section {
           .volume-field {
            margin: 0px 10px;
           }
            .ant-form-item-control-input-content {
                display: flex;
            }
        }

        .button-section {
            width: 50%;
        }

        .previous-btn {
            background-color: #FFF2D6;
            color: #000000;
        }
    }
}