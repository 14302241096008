$primary: #8B4E17;
$brown: #794E00;
$btnColor: #E68F00;

:root {
  --primary: #8B4E17;
  --brown: #794E00;
  --btn-color: #E68F00;
  --yellow-light: #FFF2D6;
  --animate-duration: 1.5s;
}