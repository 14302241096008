.table-btn{
  background: var(--btn-color);
  padding: 10px 20px;
  border-radius: 5px;
  color: #000;
  border: 0px;
  outline: none;
  font-weight: 600;
  cursor: pointer;
  box-shadow: 0px 0px 5px rgba($color: #000000, $alpha: 0.1);
  font-size: 1rem;
  display: inline-flex;
  align-items: center;
  svg {
    max-height: 24px;
  }
  .icon{
    margin-right: 10px;
  }
  &.primary{
    background: var(--btn-color);
    color: #222;
  }
  &.default {
    background: #ddd;
    color: #222;
  }
  &.sucess{
    background: #FFF2D6;
    color: #222;

  }
}