.drawer-header{
  display: flex;
  justify-content: space-between;
  align-items: center;
  h3{
    margin: 0px;
  }
  span {
    svg {
      max-width: 20px;
    }
  }
  .close-icon{
    cursor: pointer;
    display: flex;
    align-items: center;
  }
}