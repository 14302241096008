@import url("https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500;600;700;800&display=swap");
@import "./variables.scss";

html,
body {
  font-size: 16px;
  margin: 0px;
  padding: 0px;
  font-family: "Rubik", sans-serif !important;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.auth-form-select {
  height: 50px;
  .ant-select-selector {
    height: 100% !important;
    display: flex;
    align-content: center;
    padding: 0px 15px !important;
    border: 2px solid #fff2d6 !important;
    .ant-select-selection-search {
      display: flex;
      align-items: center;
    }
    .ant-select-selection-placeholder {
      display: flex;
      align-items: center;
    }
    .ant-select-selection-item {
      display: flex;
      align-items: center;
    }
  }
}
@tailwind base;
@tailwind components;
@tailwind utilities;
