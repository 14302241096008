@import url("https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500;600;700;800&display=swap");
@import "./styles/utils.scss";

html,
body {
  padding: 0px;
  margin: 0px;
  font-family: "Rubik", sans-serif !important;
}

.stat-card {
  padding: 25px;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0px 0px 8px rgba($color: #000000, $alpha: 0.1);
  text-align: center;
  .stat-card-label {
    font-size: 1rem;
    font-weight: 500;
    margin-bottom: 10px;
  }
  .stat-card-value {
    font-size: 3.5rem;
    font-weight: 400;
  }
}
.card-details {
  position: relative;
  background-color: white;
  padding: 15px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.headingone {
  font-size: 1rem;
  font-weight: 600;
  margin-bottom: 10px;
}

.col {
  display: flex;
  flex-direction: column;
}

p {
  margin: 5px 0;
  display: flex;
  justify-content: space-between;
}
.eye {
  color: rgb(186, 134, 3);
}

.icon-pin {
  position: absolute;
  top: -10px; /* Adjust the top position as needed */
  right: -15px; /* Adjust the left position as needed */
  font-size: 2rem; /* Adjust the icon size as needed */
}

.span- {
  font-weight: bold;
  color: rgb(28, 212, 93);
}

.tb-pagination {
  padding: 20px 0px !important;
  display: flex;
  align-items: center;
  .ant-pagination-prev,
  .ant-pagination-next {
    display: inline-flex;
    width: 32px !important;
    height: 32px !important;
    background: var(--btn-color);
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    &.ant-pagination-disabled {
      opacity: 0.5;
    }
  }
  .ant-pagination-item {
    color: #2d2d2d;
    border-radius: 50%;
    a {
      font-weight: 400;
      color: #2d2d2d;
      height: 32px;
      width: 32px;
      line-height: 1;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .ant-pagination-item-active {
    background: var(--yellow-light);
    border: 0px;
    border-radius: 50%;
    a {
      color: #2d2d2d !important;
    }
  }
}
.ant-dropdown-menu {
  .ant-dropdown-menu-item {
    display: flex;
    align-items: center;
    padding: 5px 10px !important;
    svg {
      max-height: 20px;
      margin-right: 5px;
    }
  }
}

@import "./styles/responsive.scss";
