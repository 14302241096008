.pet-owner-detail {
  padding: 0px 11px;

  .form-section {
    margin-bottom: 60px;

    .filterby-label {
      font-size: 1rem;
      font-weight: 500;
    }

    Input {
      margin-top: 18px;
      margin-bottom: 15px;
    }
  }
}
.form-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  
  .ImageContainer {
    width: 100%;
    max-height: 280px;
  
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
  .leftContainer {
    width: calc(100%);
   
  }
}
.formGroup {
  display: flex;
  gap: 1rem;
  .form-input {
    width: 50%;
  }
}
/* For screens equal to or larger than 768px (medium screens) */
@media (min-width: 992px) {
  .form-container {
    display: flex;
    width: 100%;
    flex-direction: row;
    gap: 1rem;
    .ImageContainer {
      width:40vw;
      height: 16vw;
      max-width: 450px;
      max-height: 280px;

      img {
        width: 100%;
        height: 100%;
      }
    }
    .leftContainer {
      width: calc(100%-450px);
    
    }
  }
}

.imageContainer {
  width: 500px;
  height: 500px;

  img {
    width: 100%;
    height: 100%;
  }
}

.deceased-icon-svg {
  svg {
    max-height: 34px !important;
  }
}
