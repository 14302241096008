.page-wrapper {
  .filter-section {
    // margin-bottom: 20px;
    margin-bottom: 0px;
    h5 {
      // margin-bottom: 5px;
    }
  }
}
.table-wrapper {
  overflow-x: auto;
  max-width: 100%;
}
.start-end-date-filter {
  .ant-form-item-label {
    label {
      font-size: 16px;
      font-weight: 600;
    }
  }
  .ant-form-item-control-input {
    .ant-picker {
      width: 200px;
      background: rgba($color: #fff, $alpha: 0.2);
    }
  }
}
