
.filter-by{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 0.56rem;
  .filterby-label{
    font-size: 1rem;
    font-weight: 500;
    margin-right: 15px;
  }
}