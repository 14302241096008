.auth-layout {
    display: flex;
    align-items: center;
    height: 100vh;

    .bg-wrapper {
        width: 50%;
        height: 100%;
        //  background: url('/images/auth-bg.png');
    }

    .auth-main-wrapper {
        text-align: center;
        height: 100vh;
        width: 50%;
        display: flex;
        flex-direction: column;
        align-items: center;
        overflow-y: auto;
    }

    .auth-form {
        padding: 50px 0;
        height: 100%;

        h1 {
            color: #8B4E17;
            text-align: left;
            margin-top: 80px;
            margin-bottom: 0px;
            font-weight: 700;
        }

        h2 {
            color: #8B4E17;
            text-align: left;
        }

        .form-wrapper {
            .ant-form-item {
                label {
                    font-weight: 400;
                }
            }

            .form-group {
                // padding: 10px;
                // font-weight: 700;
                // margin-top: 15px;
                text-align: left;

                .ant-select-selection-placeholder{
                    color: rgba($color: #000000, $alpha: 0.5);
                    font-size: 1rem;
                }

                .form-control {
                    border: 2px solid #FFF2D6;
                    padding: 10px 15px;
                    font-size: 1rem!important;
                    height: 50px;
                    input::placeholder{
                        color: rgba($color: #000000, $alpha: 0.5);
                        font-size: 1rem;
                    }
                }

                &.password-section {
                    .ant-form-item {
                        margin-bottom: 0px;
                    }
                }

                &.button-section {
                    .ant-form-item {
                        margin-bottom: 0px;
                    }
                }
            }

            .login-btn {
                background-color: #E68F00;
                color: black;
                font-weight: 600;
                width: 100%;
                margin-top: 1.0rem;
            }

            .signup-section {
                margin-top: 12px;
                font-weight: 600;
            }
        }
    }

    .onboarding-form {
        padding: 30px 0px;
        width: 100%;

        .logo-wrapper {
            text-align: right;
            margin-right: 45px;
        }

        h1 {
            color: #8B4E17;
            text-align: left;
            margin-bottom: 0px;
            font-size: 3.0rem;
            font-weight: 700;
        }
        
        h2 {
            color: #8B4E17;
            text-align: left;
            margin-top: 12px;
            font-size: 2.0rem;
            font-weight: 400;
        }

        .button-section {
            font-weight: 600;
            width: 100%;
        }

        .signup-section {
            margin-top: 12px;
            background-color: white;
            font-weight: 600;

            .signup {
                color: #8BC266;
                margin-left: 0.2rem;
            }
        }
    }
    @media (max-width: 768px) {
        .bg-wrapper {
         display: none;
        }
    
        .auth-main-wrapper {
          width: 100%; 
          padding: 0px;
        }

        .auth-main-wrapper{
            // text-align: center;
            height: 100vh;
            width: 100%;
            display: flex;
            flex-direction: column;
            // align-items: center;
            // overflow-y: auto;
        }
      }
}