.upload-image-preview{
  width: 100%;
  height: 100%;
  position: relative;
  cursor: pointer;
  .size-placeholder {
    background: #787878;
    padding: 4px 0px;
    color: #fff;
    position: absolute;
    bottom: 0px;
    width: 100%;
  }
}