.nominate-cards-wrapper {
    // background-color: #fff;
    padding: 30px;
    .nominate-card {
        padding: 25px;
        background: #fff;
        border-radius: 30px;
        box-shadow: 0px 0px 8px rgba($color: #000000, $alpha: 0.1);
        text-align: center;

        .nominate-card-heading {
            margin-top: 20px;
            font-size: 1.5rem;
            font-weight: 500;
            margin-bottom: 10px;
        }

        .image-section {}

        // .icon-section {
        //     margin-top: 40px;
        //     font-size: 3.5rem;
        //     font-weight: 400;
        // }

        .nominate-btn {
            background-color: #E68F00;
            color: black;
            font-weight: 600;
            // width: 50%;
            width: 250px;
            max-width: 100%;
            margin-top: 1.0rem;
            box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);
            border-radius: 4px;
        }
    }
}