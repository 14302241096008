.side-nav {
  padding: 10px;
  .dropdown-icon {
    font-size: 25px;
    margin-left: auto;
    margin-top: 5px;
  }
  .sub-menu {
    opacity: 0;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.5s ease, opacity 0.5s ease;
  }

  .dropdown-open .sub-menu {
    opacity: 1;
    max-height: 1000px; /* Adjust as needed */
  }
  ul {
    list-style: none;
    padding-left: 0px;

    li {
      text-align: left;
      margin-bottom: 20px;

      a {
        display: flex;
        align-items: center;
        color: #8b4e17;
        padding: 10px 20px;
        border-radius: 6px;

        .icon {
          margin-right: 10px;

          svg {
            max-height: 32px;
          }
        }

        .label {
          font-size: 1.05rem;
        }
      }

      &.selected {
        a {
          background-color: #e68f00;
          color: #000;
          svg {
            path {
              fill: #000;
            }
          }
        }
      }

      .sub-menu-item-selected a {
        background-color: #e68f00; /* Change the background color for selected sub-menu items */
        color: #000;
      }
    }
  }
}
