.wellness-plan-table {
  padding: 20px;

  .form-wrapper {
    .top-section {
      Form {
        display: flex;
        label {
          font-weight: 600;
        }
        .filter-section {
          margin-left: 0px;
          &.ant-form-item {
            margin-left: 0px;
          }
        }
        .ant-form-item {
          margin-left: 30px;
        }
      }
    }
  }
}
.inactive-row {
  background-color: #d3d3d3; /* Dark gray color */
}
.active-row-green {
  background-color: rgba(87, 197, 131, 0.284);
}
.comming {
  font-size: 50px;
  font-weight: 600;
  display: flex;
  align-items: center;
  text-align: center;
}
.FilterBoxCont {
  display: flex;
  gap: 1rem;
  align-items: flex-end;
  padding-bottom: 2rem;
  .FilterBox {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
}
.pets-10 {
  color: orange;
  font-weight: 600;
  text-align: center;
}
