.rightSide {
  padding: 20px;
  width: 50%;
}

.inputWithIcon {
  position: relative;
}

.inputWithIcon input {
  padding-right: 0px;
}

.inputWithIcon span {
  position: absolute;
  top: 30%;
  transform: translateY(-50%);
  cursor: pointer;
  right: 15px;
}

.inputWithIcon span svg {
  font-size: 1.2em;
}

@media only screen and (max-width: 768px) {
  .rightSide {
    width: 100%;
  }
  .inputWithIcon {
    position: relative;
  }

  .inputWithIcon input {
    padding-right: 120px;
  }

  .inputWithIcon span {
    position: absolute;
    top: 30%;
    transform: translateY(-50%);
    cursor: pointer;
    right: 15px;
  }
}
