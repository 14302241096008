.app-layout {
  .layout-content-wrapper {
    background: #f1f1f1;
    height: 100vh;
    overflow-y: auto;
  }
  .mobile-show-btn {
    display: inline-block;
    // padding: 20px;
    width: 32px;
    height: 32px;
    min-width: 32px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    background: #fff;
    border: 1px solid #ddd;
    border-radius: 5px;
  }
  .page-header {
    text-align: left;
    padding: 20px 30px;
    display: flex;
    align-items: center;
    h1 {
      font-size: 2.2rem;
      margin: 0px;
    }
  }
  .sidebar-wrapper {
    background: #f9f9f9;
    height: 100%;
    .logo-wrapper {
      padding: 20px 30px;
      text-align: center;
      padding-top: 28px;
      img {
        width: 100%;
        max-width: 180px;
      }
    }
  }
  .page-content {
    padding: 20px;
  }
  .right-bar {
    margin-left: auto;
    display: flex;
    align-items: center;
    gap: 15px;
  }
  .user-logged-in-dropdown-wrapper {
    display: inline-flex;
    margin-left: auto;
    position: relative;
    .dropdown-wrapper {
      background: #fff;
      position: absolute;
      right: 0px;
      top: 100%;
      z-index: 99999;
      box-shadow: 1px 2px 8px rgba($color: #000000, $alpha: 0.2);
      // width: 100%;
      ul {
        list-style: none;
        padding-left: 0px;
        padding: 0px;
        margin: 0px;
        li {
          padding: 15px 20px;
          cursor: pointer;
          border-top: 1px solid #f9f9f9;
          user-select: none;
          text-align: right;
          &:hover {
            background: #f9f9f9;
          }
        }
      }
    }
  }
  .logged-in-user-dropdown {
    display: inline-flex;
    align-items: center;
    padding: 12px 14px;
    box-shadow: 0px 0px 4px rgba($color: #000000, $alpha: 0.1);
    border-radius: 4px;
    background: #fff;
    cursor: pointer;
    margin-left: auto;
    .vet-avatar {
      margin-right: 10px;
      svg {
        max-height: 24px;
      }
    }
    .vet-name {
      font-size: 1.2rem;
      margin-right: 10px;
    }
    .dropdown-icon {
      margin-left: 10px;
      display: flex;
      align-items: center;
      svg {
        // max-width: 24px;
        max-height: 12px;
      }
    }
  }
}
.footer {
  justify-content: space-between;
}
