@media screen and (max-width: 576px) {
  .app-layout{
    .ant-layout-sider{
      position: absolute;
      left: 0px;
      width: 100%;
      height: 100%;
      z-index: 999;
      box-shadow: 0px 2px 8px rgba(0,0,0,0.2);
    }
    .page-header {
      padding: 15px!important;
      background: #fff;
      h1{
        font-size: 1.6rem!important;
        margin-left: 10px!important;
      }
    }
    .user-logged-in-dropdown-wrapper{
      display: none!important;
    }
    .logged-in-user-dropdown .vet-name {
      display: none;
    }
    .layout-action-wrapper{
      .table-btn{
        padding: 10px 10px;
      }
    }
  }
  .vsp-menu-actions-btn{
    display: flex;
    // flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    margin-top: 20px;
    flex-wrap: wrap;
    .ant-space-item{
      &:first-child {
        width: 46%;
        .table-btn{
          width: 100%;
        }
      }
      &:nth-child(2) {
        width: 46%;
        margin-left: auto;
        .table-btn{
          width: 100%;
        }
      }
      &:nth-child(3) {
        width: 100%;
      }
    }
  }
  .mobile-show-hide-btn{
    display: block;
    // padding: 20px;
    width: 32px;
    height: 32px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    background: #fff;
    border: 1px solid #ddd;
    border-radius: 5px;
  }
  .logo-wrapper{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 15px!important;
  }
  .ant-table-content{
    width: 100%;
    overflow-x: auto;
    .ant-table-cell {
      min-width: 120px;
    }
  }
  .start-end-date-filter .ant-form-item-control-input .ant-picker{
    width: 100%!important;
  }
  .wellness-plan-table{
    .top-section{
      .ant-form {
        display: block!important;
        margin-bottom: 20px!important;
      }
      .ant-form-item {
        width: 100%;
        margin: 0px!important;
        margin-bottom: 10px!important;
        .ant-form-item-label{
          padding-bottom: 0px!important;
        }
        .ant-picker{
          width: 100%;
        }
      }
    }
  }
  .wellness-plan-table{
    padding: 0px!important;
  }
  .ant-table-wrapper .ant-table-pagination-right{
    justify-content: center!important;
  }
  .ant-drawer-content-wrapper{
    width: calc(100% - 40px)!important;
    margin: 20px!important;
  }
  .nominate-cards-wrapper{
    padding: 20px 30px 0px 30px!important;
  }
  .vsp-dashboard-table{
    padding: 0px!important;
  }
}
@media screen and (min-width: 576px) {
  .mobile-show-hide-btn{
    display: none;
  }
  .mobile-show-btn{
    display: none!important;
  }
}