.schedule-meeting-wrapper {
    .schedule-btn {
        background-color: #FFF2D6;
        color: black;
        font-weight: 600;
        width: 250px;
        max-width: 100%;
        margin-top: 1.0rem;
        box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);
        border-radius: 4px;
    }

    .schedule-btn:hover {
        background-color: #FFF2D6;
        color: black;
    }
}