// VSPSingleRequest.scss
.vsp-single-detail {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;

  .form-section {
    margin-bottom: 30px;

    h1 {
      font-size: 24px;
      margin-bottom: 16px;
    }

    .ant-form-item {
      margin-bottom: 16px;

      .ant-form-item-label {
        font-weight: bold;
      }

      .ant-form-item-control-input {
        input.disabled-input {
          background-color: #f5f5f5;
          border: 1px solid #d9d9d9;
          color: #333;
          width: 100%;
          padding: 10px;
          border-radius: 5px;
        }
      }
    }
  }
}
