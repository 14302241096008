.signup-form {
  // padding: 30px;
  padding: 30px 0px;
  width: 100%;
  background: #f9f9f9;

  .logo-wrapper {
    text-align: right;
    margin-right: 25px;
  }

  .page-header-wrap {
    padding: 0px 30px;
  }

  h1 {
    position: relative;
    bottom: 40px;
    margin: 0px 20px !important;
    font-size: 4rem;
  }

  h2 {
    position: relative;
    bottom: 40px;
    margin-top: 0px;
    font-size: 2.5rem;
    margin: 0px 20px;
    font-weight: 400 !important;
  }

  .user-avatar-icon {
    background: #d9d9d9;
    width: 80px;
    height: 80px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 20px;
    position: relative;

    .plus-icon {
      position: absolute;
      right: -14px;
      bottom: -14px;
    }
  }

  .form-wrapper {
    .ant-form-item {
      margin: 0px;
    }

    .signup-icon {
      position: relative;
      bottom: 30px;
      font-size: 1.5rem;
      background-color: lightgray;
      padding: 15px;
      border-radius: 10px;
      text-align: center;
    }

    .form-group {
      margin-top: 10px;

      .login-form-forgot {
        color: #8bc266;
        margin-bottom: 0px;
        text-align: right;
        float: right;
        font-weight: 400;
        padding: 0px;
      }
    }

    .signup-section {
      .signup {
        color: #8bc266;
        margin-left: 0.2rem;
      }

      .forget-btn {
        font-weight: 700;
      }
    }
  }

  .button-section {
    button {
      margin-bottom: 15px;
      padding: 10px 10px !important;
      height: auto !important;
      font-weight: 600;
      &:last-child {
        margin-bottom: 0px;
      }
    }
    .previous-btn {
      background: #dddddd !important;
      &.disabled {
        color: #999 !important;
      }
      &:hover {
        color: #999 !important;
      }
    }
  }

  .signupform-loading-overlay {
    position: absolute;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    background: rgba($color: #fff, $alpha: 0.4);
    z-index: 9999;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: -20px;
    width: calc(100% + 40px);
    height: calc(100% + 40px);
  }

  .ant-form-item-control {
    .ant-upload-select {
      width: 100%;
    }
    .ant-form-item-explain-error {
      width: 100%;
      text-align: left;
      padding: 5px 5px;
      font-weight: 400;
    }
  }
}

.input-suffix-icon {
  display: inline-flex;
  align-items: center;
  justify-content: flex-end;
  svg {
    max-height: 18px;
  }
}
.form__container {
  padding: 0 5rem;
  padding-bottom: 2rem;
}
