// PetVisitsTable.scss
.pet-visits-table {
  .form-wrapper {
    padding: 30px;
    background-color: #f5f5f5;
    margin-bottom: 20px;
    border-radius: 10px;
    .table-wrapper {
      overflow-x: auto;
      max-width: 100%;
    }
    .top-section {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .ant-form-item {
        margin-bottom: 0;
      }

      .ant-btn {
        background-color: rgb(227, 140, 33);
        margin-right: 10px;
        color: black;
        font-weight: 600;
        border-radius: 10px;
        width: 100%; // Set a fixed width for the buttons
        height: 50px; // Set a height for the buttons
        font-size: 14px; // Adjust font size if needed
        display: flex;
        justify-content: center;
        align-items: center;

        &:last-child {
          margin-right: 0;
        }

        svg {
          margin-right: 5px;
        }
      }
    }
  }

  .last-appointment {
    background-color: rgba(0, 128, 0, 0.2);
    padding: 5px 10px;
    margin: 5px 0;
    text-align: center;
    color: green;
    border-radius: 5px;
  }

  .deceased-icon {
    text-align: center;
  }
  @media screen and (max-width: 768px) {
    .ant-btn {
      width: 100%; // Set full width for buttons on smaller screens
      margin-right: 0;
      margin-top: 10px; // Remove right margin for buttons on smaller screens
    }
  }
}
